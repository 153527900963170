import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_SERVICE_API;//'https://localhost:7156/';
//axios.defaults.baseURL = 'https://wuttanavij.azurewebsites.net/'
//axios.defaults.baseURL = 'https://wellekpharma.azurewebsites.net/'
axios.defaults.headers.common["Authorization"]="Bearer " + window.localStorage.token;
export default {
    namespaced: true,
    state: {
        api: false
    },
    getters: {
    },
    actions: {
        getHistoryList(context, data){
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/Historys/getHistory?pageNum=' + data.pageNum + '&find=' + data.find + '&startDate=' + data.startDate + '&endDate=' + data.endDate, { 'Content-Type': 'application/json' }).then((result) => {
                    context.commit('setApi');
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getSummaryHistoryOrder(context){
            console.log(context);
            var orderId = window.localStorage.getItem('history_order');
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/summaryList?find='+orderId, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getAdminSummaryHisOrder(context, orderId){
            console.log(context);
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/summaryList?find='+orderId, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        openPDF(context, orderId){
            return new Promise((resolve) => {
                axios.get('api/pdf/export?wos=' + orderId, { responseType: 'blob' }).then((result)=>{
                    resolve(result.data);
                });
            });
            
        }
    },
    mutations: {
        setApi(state) {
            state.api = true;
        }
    },
}