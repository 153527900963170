import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_SERVICE_API;//'https://localhost:7156/';
//axios.defaults.baseURL = 'https://wuttanavij.azurewebsites.net/'
//axios.defaults.baseURL = 'https://wellekpharma.azurewebsites.net/'
axios.defaults.headers.common["Authorization"]="Bearer " + window.localStorage.token;
export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    actions: {
        getBanner(context, data){
            return new Promise((resolve, reject) => {
                axios.get('Cloud/AdsBanner/GetBanner?id=' + data.id + '&itype=' + data.itype, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        saveBanner(context, data){
            return new Promise((resolve, reject)=>{
                axios.post('Cloud/AdsBanner/RecodeBanner',data, {'Content-Type': 'application/json'}).then((result)=>{
                    resolve(result.data);
                }).catch((error)=>reject(error));
            });
        },
        recordMessage(context, data){
            return new Promise((resolve, reject)=>{
                axios.post('Cloud/AdsBanner/MessageRecord',data, {'Content-Type': 'application/json'}).then((result)=>{
                    resolve(result.data);
                }).catch((error)=>reject(error));
            });
        },
        bannerViewer(context, data){
            return new Promise((resolve, reject) => {
                axios.get('Cloud/AdsBanner/GetBannerViewer?id=' + data.id + '&itype=' + data.itype, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        }
    },
    mutations: {
       
    }
}