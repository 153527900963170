import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
const routes = [
    //{ name: 'login', path: '/', component: ()=>import("../pageViews/securityLogin.vue")},
    { 
        name: 'firstpage', 
        path: '/', 
        redirect: '/promotion',
        component: ()=>import("../pageViews/indexPage.vue"),
        children:[
            {name: 'promotion', path: 'promotion', component:()=>import('../pageViews/views/promotionView.vue')},
            {name: 'newproduct', path: 'newproduct', component:()=>import('../pageViews/views/newproduictView.vue')},
            {name: 'recomment', path: 'recomment', component:()=>import('../pageViews/views/reccommentView.vue')},//
            {name: 'productchange', path: 'productchange', component:()=>import('../pageViews/views/productChageView.vue')}
        ]
    },
    {
        name: 'categoryList',
        path: '/product-viewer',
        component: ()=>import('@/pageViews/productListViewer.vue')
    },
    { name: 'receipt', path: '/Receipt', params:{vs:1}, component:()=>import('@/pageViews/receiptPage.vue')},
    { name: 'contact', path: '/ContactUs', params:{vs:1}, component:()=>import('@/pageViews/contactPage.vue')},
    { name: 'receipts', path: '/Receipts', params:{vs:1}, component:()=>import('@/pageViews/receiptPages.vue'),meta: { requiresAuth: true }},
    { name: 'contacts', path: '/ContactUss', params:{vs:1}, component:()=>import('@/pageViews/contactPages.vue'),meta: { requiresAuth: true }},
    {
        name:'sign',
        path: '/login',
        component:()=>import('../pageViews/loginPages.vue')
    },
    { name: 'register', path: '/register?vs=1', component: ()=>import("../pageViews/registerMember.vue")},
    { 
        name: 'categoryLists', 
        path: '/product-items', 
        component:()=>import("@/pageViews/productListViewers.vue") , 
        meta: { requiresAuth: true }
    },
    { 
        name: 'history', 
        path: '/order-history', 
        component:()=>import("@/pageViews/historyViewer.vue") , 
        meta: { requiresAuth: true }
    },
    { 
        name: 'verify',
        path: '/password-reset',
        component: ()=>import("@/pageViews/resetPage.vue")
    },
    { 
        name: 'main',
        path: '/home',
        component: ()=>import("@/pageViews/homeScreen.vue"),meta: {requiresAuth: true},
        children: [
            { name: 'promotions', path: 'promotions', component:()=>import('@/pageViews/views/viewers/promotionViews.vue'), meta: { requiresAuth: true }},
            { name: 'newproducts', path: 'newproducts', component:()=>import('@/pageViews/views/viewers/newproduictViews.vue'), meta: { requiresAuth: true }},
            { name: 'recomments', path: 'recomments', component:()=>import('@/pageViews/views/viewers/reccommentViews.vue'), meta: { requiresAuth: true }},
            { name: 'productchanges', path: 'productchanges', component:()=>import('@/pageViews/views/viewers/productChageViews.vue'), meta: { requiresAuth: true }}
            
            /*{ name: 'home', path: 'Products', component: ()=>import('@/pageViews/views/products/homePage.vue'), meta: {requiresAuth: true}},
            { name: 'product', path: 'items', component: ()=>import('@/pageViews/views/products/productDetail.vue'), meta: {requiresAuth:true}},
            { name: 'find-items', path: 'find-item', query:{ vs: 2}, component:()=>import('@/pageViews/views/products/productView.vue'), meta: { requiresAuth: true}},
            { name: 'summary', path: 'Summary', params:{ vs:0} , component: ()=>import('@/pageViews/views/products/summaryView.vue'), meta: {requiresAuth: true}},
           { name: 'history', path: 'history' , component:()=>import('@/pageViews/views/products/historyView.vue'), meta: {requiresAuth: true}}
            { name: 'products', path: 'List-Products', params:{vs:2} , component:()=>import('@/pageViews/views/products/listProduct.vue'), meta: {requiresAuth: true}},
            { name: 'order_history', path: 'SummaryHistory', params:{vs:1} , component:()=>import('@/pageViews/views/products/summaryHistoryView.vue'), meta: {requiresAuth: true}},
            { name: 'profile', path: 'Profile', params:{vs:1}, component:()=>import('@/pageViews/views/products/profileView.vue'), meta: {requiresAuth:true}},
            { name: 'receipt', path: 'Receipt', params:{vs:1}, component:()=>import('@/pageViews/receiptPage.vue'), meta: {requiresAuth:true}},*/
        ]
    },
    { 
        name: 'admin', 
        path: '/admin_page', 
        component: ()=>import("@/pageViews/adminPage.vue"),
        children: [
            { path: 'register', params:{ 'vs': 1 }, component: ()=>import("@/pageViews/views/masters/requestRegister.vue"), meta: { requiresAuth: true } },
            { path: 'member', params:{ 'vs': 2 }, component: ()=>import("@/pageViews/views/masters/memberForm.vue"), meta: { requiresAuth: true}},
            { path: 'products', params:{ 'vs': 3 }, component: ()=>import("@/pageViews/views/masters/productForm.vue"), meta: {requiresAuth: true}},
            { path: 'history', params:{ 'vs': 4 }, component: ()=>import("@/pageViews/views/masters/historyForm.vue"), meta: {requiresAuth:true}},
            { path: 'banner', params:{ 'vs': 5}, component: ()=>import("@/pageViews/views/masters/bannerForm.vue"), meta:{requiresAuth:true}},
            { path: 'ads', params:{ 'vs': 6}, component: ()=>import("@/pageViews/views/masters/adsForm.vue"), meta:{requiresAuth:true}},
            { path: 'chatroom', params: {'vs': 9}, component:()=>import("@/pageViews/views/masters/messageForm.vue"), meta: {requiresAuth: true}},
            { path: 'promotions', params: {'vs': 7}, component:()=>import("@/pageViews/views/masters/promotionsForm.vue"), meta: {requiresAuth: true}},
            { path: 'company', params: {'vs': 8}, component:()=>import("@/pageViews/views/masters/companyProfile.vue"), meta: {requiresAuth: true}}
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    if (requiresAuth) {
        var token = localStorage.getItem('token');
        token??='';
        //console.log(token);
        if (token != '' && token != null) {
            next();
        } else {
            next({ name: "login" })
        }
    } else {
        next()
    }
    next()
})
export default router