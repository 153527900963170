import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_SERVICE_API;//'https://localhost:7156/';
//axios.defaults.baseURL = 'https://wuttanavij.azurewebsites.net/'
//axios.defaults.baseURL = 'https://wellekpharma.azurewebsites.net/'
//axios.defaults.headers.common["Authorization"]="Bearer " + window.localStorage.token;
import { Base64 } from 'js-base64';
export default {
    namespaced: true,
    state: {
        itemProduct: null,
    },
    getters: {
        getSelectedItem(state){
            return state.itemProduct;
        }
    },
    actions: {
        async getStateProduct(context){
            return  context.state.itemProduct;
        },
        getProductItemsView(context, data){
            //const orderid = 0; //context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
           //console.log(axios);
            return new Promise((resolve, reject) => {
                axios.post('Cloud/ProductLists/ProductListResultOnly',data, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { 
                    reject(error); });
                //axios.get('Cloud/ProductLists/ProductListResultOnly?find='+data.find+'&pageNum='+data.pageIndex+'&orderId=' + orderid == null?0:orderid, { 'Content-Type': 'application/json' }).then((result) => {
                //    resolve(result.data);
                //}).catch((error) => { 
                //    reject(error); });
            })
        },
        getRecomment(context, data){
            console.log(context);
            const orderid = 0; //context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/RecommentOnly?find='+data.find+'&pageNum='+data.pageIndex+'&orderId=' + orderid, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getPromotion(context, data){
            //console.log(context);
            const orderid =0;// context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/PromotionReadOnly?find='+data.find+'&pageNum='+data.pageIndex+'&orderId=' + orderid, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getProductList(context,data){
            const orderid =  context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/ProductList?mode=' + data.mode + '&find='+data.find+'&pageNum='+data.pageIndex+'&orderId=' + orderid, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },

        getProductListView(context,data){
            const orderid = 0;// context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/ProductListView?mode=' + data.mode + '&find='+data.find+'&pageNum='+data.pageIndex+'&orderId=' + orderid, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },


        getStockType(){
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/ProductType', { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getPromotionList(context, data){
            console.log(context);
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/PromotionScope?find='+data.find, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        addItems(context, data){
            data.cusOrder = context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return  new Promise((resolve, reject) => {
                axios.post('Cloud/ProductLists/customerOrder', data, { 'Content-Type': 'application/json' }).then((result) => {
                    //context.commit('setToken', result.data.token);
                    localStorage.setItem('orderNo', result.data.orderId);
                    context.rootState.orderId = result.data.orderId;
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getShoppingCounter(context){
            var orderId = context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/shopCouter?find='+orderId, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getSummaryOrder(context){
            var orderId = context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/summaryList?find='+orderId, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getDeliveryType(){
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/deliveryType', { 'Content-Type': 'application/json' }).then((result) => {
                    //console.log(result.data);
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getPaymentType(){
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/ProductLists/paymentType', { 'Content-Type': 'application/json' }).then((result) => {
                    //console.log(result.data);
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        confirmOrder(context, data){
            data.cusOrder = context.rootState.orderId == 0?window.localStorage.getItem('orderNo'):context.rootState.orderId;
            return  new Promise((resolve, reject) => {
                axios.post('Cloud/ProductLists/confirmOrder', data, { 'Content-Type': 'application/json' }).then((result) => {
                    localStorage.setItem('orderNo', 0);
                    context.rootState.orderId = 0;
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        profileView(){
            return  new Promise((resolve, reject) => {
                axios.get('Cloud/Securitys/userProfile', { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        companyProfile(){
            return new Promise((resolve, reject) =>{
                axios.get('Cloud/Securitys/companyProfile', { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        companyUpdate(context, data){
            return  new Promise((resolve, reject) => {
                axios.post('Cloud/Securitys/updateCompanyProfile', data, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getPricConvertProduct(context, data){
            return  new Promise((resolve, reject) => {
                axios.post('Cloud/ProductLists/GetPriceChangeMode', data, { 'Content-Type': 'application/json' }).then((result) => {
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getStockTypeMaster(){
            return new Promise((resolve, reject)=>{
                axios.get('Cloud/ProductLists/GetStockTypeFind').then((result)=>{
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        },
        getStockGroupMaster(){
            return new Promise((resolve, reject)=>{
                axios.get('Cloud/ProductLists/GetGroupTypeFind').then((result)=>{
                    resolve(result.data);
                }).catch((error) => { reject(error); });
            })
        }

    },
    mutations: {
       assignProduct(state, item){
        window.localStorage.setItem('items',Base64.encode(JSON.stringify(item)));
        state.itemProduct = item;
       }
    }
}
