<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  html, body{
        font-family: 'Kanit', 'Prompt', sans-serif !important;
        font-weight: 200 !important;
        font-size: 15px !important;
        color:black;
  }
  @media only screen and (max-width: 700px) {
    .reg-top{
      margin-top: 10px !important;
    }
  }
  .reg-top{
    margin-top: 155px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .top-header{
   background: linear-gradient(#033479,#3953e6);
  }
  .sub-header{
    /*background-color: #3953e6fa;*/
    background-color: white;
  }
  .text-white>a{
    color: #c7c7c7 !important;
  }
</style>
