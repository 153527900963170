import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router  from './routers/index.js'
import store from './store/index.js'
import VueNativeSock from 'vue-native-websocket'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)
Vue.use(VueNativeSock, process.env.VUE_APP_CHAT_API,{connectManually: true,format: 'json'})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
